body {
  margin: 0;
  background-color: rgb(223, 223, 223);
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25vh;
}

.cards img:nth-child(1) { top: 0px; position: absolute;}
.cards img:nth-child(2) { top: 10px; position: absolute;}
.cards img:nth-child(3) { top: 20px; position: absolute;}
.cards img:nth-child(4) { top: 30px; position: absolute;}
.cards img:nth-child(5) { top: 40px; position: absolute;}

.card {
  display: inline-block;
  height: 30vh;
  width: auto;
  max-width: 230px;
  min-height: 100px;
  max-height: 250px;
  margin: 0 0;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.game-button {
  display: inline-block;
  padding: 10px 20px; /* Der horizontale Abstand (20px) bestimmt den Abstand links und rechts vom Text, der vertikale Abstand (10px) die Höhe */
  border: none;
  border-radius: 5px; /* Abrundung der Ecken für ein ansprechendes Aussehen */
  background-color: #007BFF; /* Blauer Hintergrund */
  color: white; /* Weißer Text */
  text-align: center;
  font-size: 16px; /* Schriftgröße */
  cursor: pointer; /* Hand-Cursor beim Darüberfahren */
  transition: background-color 0.3s ease; /* Übergangseffekt für den Hintergrund */
  margin-right: 10px;
}

.game-button:last-child {
  margin-right: 0px;
}

.game-button:hover {
  background-color: #0056b3; /* Dunklerer Blauton beim Darüberfahren */
}

.container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 500px;
  max-width: 1000px
}

.playersection {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #00000045;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.915);
  border-radius: 20px 0 0 0;
  padding: 10px;
}

.balance {
  color: white;
  margin-bottom: 5px;
}